import React from "react"

import IndexLayout from '../layouts'

const BlogPage = () => {
  return (
    <IndexLayout>
      
    </IndexLayout>
  )
}

export default BlogPage